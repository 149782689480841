<template>
  <div class="bgFFF">
    <el-form ref="form" :model="temp" :rules="rules" label-position="right" label-width="100px">
      <el-form-item :label="$t('searchModule.Event_Name')" style="width: 500px" prop="discountName">
        <el-input
          v-model="temp.discountName"
          :disabled="isDetail"
          maxlength="20"
          placeholder="请输入活动名称"
        />
      </el-form-item>
      <!-- <el-form-item :label="$t('searchModule.park_name')" prop="parkId">
        <el-select v-model="temp.parkId" :disabled="isDetail">
          <el-option v-for="item in parkingData" :key="item.parkId" :value="item.parkId" :label="item.parkName"/>
        </el-select>
      </el-form-item> -->
      <el-form-item :label="$t('searchModule.park_name')" prop="parkName">
        <el-autocomplete
          v-model="temp.parkName"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入内容"
          value-key="parkName"
          :disabled="isDetail"
          :trigger-on-focus="false"
          @select="handleSelect"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="折扣" style="width: 500px" prop="discount">
        <el-input
          v-model="temp.discount"
          :disabled="isDetail"
          maxlength="10"
          placeholder="请输入折扣"
        />
      </el-form-item>
      <el-form-item label="活动时间" prop="dateArray">
        <el-date-picker
          v-model="temp.dateArray"
          type="daterange"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :disabled="isDetail"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div style="text-align: center; padding: 20px 0">
      <el-button v-if="!isDetail" type="primary" @click="submit">提交</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      temp: {
        discountName: "",
        parkName: "",
        parkId: "",
        discount: "",
        dateArray: [],
      },
      rules: {
        discountName: [{ required: true, message: "请填入活动名称", trigger: "blur" }],
        parkName: [{ required: true, message: "请选择停车场名称", trigger: "change" }],
        discount: [
          { required: true, message: "请输入折扣", trigger: "blur" },
          { pattern: /^[1-9]\d?$/, message: "只能输入0-99之间的整数" },
        ],
        dateArray: [{ required: true, message: "请选择活动时间", trigger: "change" }],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      parkingData: [],
      isDetail: this.$route.path === "/yardDiscountDetail",
      discountId: this.$route.query.discountId,
    };
  },
  methods: {
    getParkingData() {
      const tempData = {
        page: 1,
        pageSize: 100,
      };
      const opt = {
        method: "get",
        url: "/acb/2.0/systems/loginUser/getParkName",
        data: tempData,
        success: (res) => {
          this.parkingData = res.value.list;
        },
      };
      this.$request(opt);
    },
    getDetailInfo() {
      const opt = {
        method: "get",
        url: "/acb/2.0/parkDiscount/byId/" + this.discountId,
        data: {},
        success: (res) => {
          const value = res.value;
          this.temp.discountName = value.discountName;
          this.temp.parkId = value.parkId;
          this.temp.parkName = value.parkName;
          this.temp.discount = value.discount;
          this.temp.dateArray = [value.startTime, value.endTime];
        },
      };
      this.$request(opt);
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temp.parkId = "";
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.temp.parkId = item.parkId;
      this.temp.parkName = item.parkName;
    },
    submit() {
      const tempData = {
        discountName: this.temp.discountName,
        parkId: this.temp.parkId,
        discount: this.temp.discount,
        startTime: this.temp.dateArray[0] + " 00:00:00",
        endTime: this.temp.dateArray[1] + " 23:59:59",
      };
      let url = "";
      let method = "";
      if (this.discountId) {
        url = "/acb/2.0/parkDiscount/update";
        method = "post";
        tempData.discountId = this.discountId;
      } else {
        url = "/acb/2.0/parkDiscount/create";
        method = "post";
      }
      const opt = {
        method,
        url,
        data: tempData,
        success: (res) => {
          this.$router.push({ path: "/yardDiscount" });
        },
      };
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$request(opt);
        }
      });
    },
  },
  mounted() {
    this.getParkingData();
    if (this.discountId) {
      this.getDetailInfo();
    }
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper
  overflow hidden
.breadcrumb
  height 50px
.content
  background #FFFFFF
  overflow hidden
  border-radius 4px
  border 1px solid #d9d9d9
  padding 20px
</style>
